const config = {
  perPage: 20,
  superAdminDateTimeFormat: 'DD MMM, YYYY hh:mm A', // This is NOT to be used in company or request context!
  streamKey: process.env.NEXT_PUBLIC_STREAM_KEY,

  // developers may want their own dev id -- edit this string but don't check in to GitHub
  launchDarklyClientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_ID || '5db074bef559b808a6d1e37f',

  streamNotificationsKey: process.env.NEXT_PUBLIC_STREAM_NOTIFICATIONS_KEY || 'zsq32mmzdbnt',
  streamNotificationsAppID: process.env.NEXT_PUBLIC_STREAM_NOTIFICATIONS_APPID || '62183'
};

export default config;
