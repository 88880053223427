import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Col, Row } from '@arkestro/arkestro-design-system';
import useTranslation from 'next-translate/useTranslation';

const LABEL_STYLE = {
  color: '#95979e',
  fontSize: 14,
  textTransform: 'none'
};

const InputPhone = ({ value, onChange }) => {
  const { t } = useTranslation('common');
  const handleChange = eventValue => {
    onChange({
      target: {
        name: 'phone',
        value: eventValue
      }
    });
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <label style={LABEL_STYLE} htmlFor='phone'>{t('general.phone')}</label>
        </Col>
      </Row>
      <PhoneInput
        value={value.replace(/\D/g,'')} // remove non-numeric characters
        onChange={handleChange}
        country='us'
        preferredCountries={['us', 'cn', 'de']}
        placeholder=''
        inputClass='phone-input'
        dropdownClass='standard-scrollbar standard-phone-dropdown'
        inputExtraProps={{
          name: 'phone'
        }}
        inputProps={{
          autoComplete: 'off'
        }}
        countryCodeEditable={false}
        enableSearchField
        disableSearchIcon
      />
    </>
  );
};

InputPhone.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

InputPhone.defaultProps = {
  value: '',
  onChange: () => {}
};

export default InputPhone;
