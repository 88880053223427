import { useContext, useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { Menu, Dropdown } from 'antd-v3';
import { ApiFilled, BankOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';

import Routes from '@services/Routes';
import { UserContext } from '@providers/UserProvider/UserProvider';
import { alertMessage } from '@utils/common';
import { combineClassNames } from '@utils/classNames';
import { OPENED_ACCOUNT_MENU } from '@utils/analytics_constants';
import { attachTrackingAnalytics } from '@services/SegmentService';
import { signOut, stopImpersonating } from '@api/AuthApi';
import NotificationsProvider from '@components/providers/NotificationsProvider/NotificationsProvider';
import NotificationsDropdownButton from
  '@components/layout/Header/Notifications/NotificationsDropdownButton/NotificationsDropdownButton';

import UserInfoDisplay from './UserInfoDisplay';
import ImpersonatingEmail from './ImpersonateEmail';
import style from './SettingsDisplay.module.scss';

const vendorOrgId = user => {
  if (!user || !user.vendor_profile) return undefined;

  return user.vendor_profile.vendor_organization_id;
};

const ProfilePicture = user => {
  const { t } = useTranslation('common');
  const picture = user.profile_picture_url;
  const size = 40;

  return picture ? (
    <Image
      style={{ borderRadius: 50 }}
      height={size}
      width={size}
      src={picture}
      alt={t('general.your_user_avatar')}
    />
  ) : (
    <UserOutlined style={{ fontSize: size }} />
  );
};

const handleStopImpersonating = t => {
  stopImpersonating().then(results => {
    if (results?.response?.data?.errors) {
      alertMessage(results.response.data.errors[0], 'error', 6);
      return;
    }

    alertMessage(t('general.no_longer_impersonating'));
    window.location = Routes.SUPER_ROOT_PATH;
  })
    .catch(() => {
      alertMessage(t('errors.stop_impersonating_settings'), 'error', 6);
    });
};

const handleSignOut = (router, user) => {
  signOut().then(response => {
    if (response.status !== 204) return;

    user.clearUserData();
    router.push('/login');
  });
};

const renderMenu = (user, favUsers, showOrganizationSettings, t, router) => (
  <Menu>
    <div className={style.userDisplayMenu}>
      <UserInfoDisplay />
    </div>
    {!!user.id && !user.is_deeplink && !user.is_super_admin && (
      <Menu.Item className={style.menuItem}>
        <Link href='/settings/profile'>
          <a>
            <SettingOutlined className={style.settingsIcon} />
            <span>{t('general.settings')}</span>
          </a>
        </Link>
      </Menu.Item>
    )}
    {showOrganizationSettings && user.is_buyer && user.is_admin && !user.is_deeplink && (
      <Menu.Item className={style.menuItem}>
        <Link href='/organization'>
          <a>
            <BankOutlined className={style.settingsIcon} />
            <span>{t('general.my_organization')}</span>
          </a>
        </Link>
      </Menu.Item>
    )}
    {user.is_supplier && vendorOrgId(user) && !user.is_deeplink && (
      <Menu.Item className={style.menuItem}>
        <Link href={`/supplier_organizations/${vendorOrgId(user)}`}>
          <a>
            <UserOutlined />
            <span>{` ${t('general.my_organization')}`}</span>
          </a>
        </Link>
      </Menu.Item>
    )}
    {user.is_buyer && user.role === 'owner' &&
      <Menu.Item className={style.menuItem}>
        <Link href='/personal_access_tokens'>
          <span>
            <ApiFilled className={style.settingsIcon} />
            {`${t('personal_access_tokens.title')}`}
          </span>
        </Link>
      </Menu.Item>
    }
    <Menu.Item className={style.menuItem}>
      <a rel='noopener noreferrer' onClick={() => handleSignOut(router, user)}>
        <LogoutOutlined className={style.settingsIcon} />
        <span>{t('general.sign_out')}</span>
      </a>
    </Menu.Item>
    {user.impersonating && (
      <Menu.Item className={style.menuItem}>
        <span onClick={() => handleStopImpersonating(t)}>
          <LogoutOutlined className={combineClassNames(style.settingsIcon, 'icon-red')} />
          {t('general.stop_impersonating')}
        </span>
      </Menu.Item>
    )}
    {user.impersonating && <Menu.Divider />}
    {user.impersonating && (
      Object.entries(favUsers || {}).map(([id, email]) => (
        <Menu.Item className={style.menuItem} key={id}>
          <ImpersonatingEmail key={id} id={id} email={email} />
        </Menu.Item>
      ))
    )}
  </Menu>
);

/**
 * LaunchDarkly Flags: showOrganizationSettings
 */
const SettingsDisplay = () => {
  const router = useRouter();
  const user = useContext(UserContext);
  const [favoriteUsers, setFavoriteUsers] = useState('');
  const { t } = useTranslation('common');

  const favUsers =
    Object.fromEntries(
      favoriteUsers
        .split(',')
        .map(fav => fav.split('&'))
        .filter(fav => !!fav[0] && !!fav[1])
    ) || {};
  const { showOrganizationSettings } = useFlags();

  useEffect(() => {
    setFavoriteUsers(window.localStorage.getItem('FavoriteUsers') || '');
  }, []);

  return (
    <div className={style.wrapper} id='dropdrown-wrapper'>
      <div className={style.dropdownWrapper}>
        <div className={style.dropdownContainer}>
          <Dropdown
            getPopupContainer={trigger => trigger.parentElement}
            trigger={['click']}
            overlay={renderMenu(user, favUsers, showOrganizationSettings, t, router)}
            overlayClassName={`${style.dropdown} standard`}
            placement='bottomRight'
            onVisibleChange={visible => {
              if (visible) attachTrackingAnalytics(OPENED_ACCOUNT_MENU);
            }}
          >
            {/* This link MUST be here in order for the menu to work */}
            <a onClick={e => e.preventDefault()}>
              <ProfilePicture user={user} />
            </a>
          </Dropdown>
        </div>
      </div>
      {/* A real User object may not exist in development and on /sidekiq path */}
      {!!user.id && <UserInfoDisplay rightAlign />}
      {!!user.id && !user.is_super_admin && !user.is_deeplink && (
        <div className={style.notificationsButton}>
          <NotificationsProvider>
            <NotificationsDropdownButton />
          </NotificationsProvider>
        </div>
      )}
      <div style={{ clear: 'both' }} />
    </div>
  );
};

export default SettingsDisplay;
