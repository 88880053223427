import { notification } from '@arkestro/arkestro-design-system';
import { generateDownloadFromUrl } from 'utils/files';

const BID_SUBMISSION_PATH = '/bid_submission';

export const ORGANIZATION_PATH = '/organization';

export const BID_SUBMISSION_PROCESSING_NOTIF_KEY = 'bid_submission_processing';
export const BID_SUBMISSION_SUBMITTED_NOTIF_KEY = 'bid_submitted';
const IGNORED_DATA = ['award_scenario', 'created_award_scenario', 'updated_award_scenario'];

const shouldIgnoreData = (data) => {
  return IGNORED_DATA.some((key) => data[key]);
};

class PusherService {
  constructor (data, provider) {
    this.data = data;
    this.provider = provider;
    this.router = provider.props.router;
  }

  // type can be 'success', 'info', 'warning', 'error'
  static notifyClient = ({
    messageDescription,
    messageDuration,
    messageTitle,
    type = 'info',
    key = undefined
  }) => {
    notification[type]({
      key,
      message: messageTitle,
      description: messageDescription,
      duration: messageDuration
    });
  }

  handleEvents = () => {
    if (!this.data || Object.keys(this.data).length === 0) return;

    const {
      messageTitle,
      messageDescription,
      messageDuration,
      messageType = 'success',
      key
    } = this.generateMessageInfo();

    if (messageTitle) {
      PusherService.notifyClient({
        messageDescription,
        messageDuration,
        messageTitle,
        type: messageType,
        key
      });
    }
  }

  generateMessageInfo = () => {
    const {
      job_error: jobError = {},
      cloned_bid_request: clonedRequest,
      bid_submission: bidSubmission,
      file_download: fileDownload,
      onboard_transactions: onboardTransactions,
    } = this.data;
    const pathName = this.router.pathname;
    const isBidSubmissionPage = pathName.indexOf(BID_SUBMISSION_PATH) > -1;
    const isOrganizationPage = pathName.indexOf(ORGANIZATION_PATH) > -1;
    const hasJobError = Object.keys(jobError).length > 0;

    let messageType = 'success';

    let key;

    let messageTitle = this.provider.props.i18n.t('general.notification');

    let messageDescription;

    let messageDuration = 8;

    // No op since we are updating the page directly, future work should update how we parse pusher data here
    if (shouldIgnoreData(this.data)) {
      return {
        messageTitle: null
      };
    }

    if (jobError.message) {
      messageType = 'error';
      messageTitle = jobError.title || messageTitle;
      messageDescription = jobError.message;
    } else {
      if (onboardTransactions) {
        if (onboardTransactions.status === 'Completed') {
          if (isOrganizationPage) {
            // setting messageDuration=0 means never auto dismiss.
            // since the user will see a loading state until they click
            // we don't want to auto dismiss the message
            // - Alex Ispa-Cowan May 22, 2023
            messageDuration = 0;
            messageDescription = (
              <a href='/organization/transactions' rel='noreferrer'>
                {this.provider.props.i18n.t('importing.click_to_view_data')}
              </a>
            );
          } else {
            messageDescription = (
              <a href='/organization/transactions' target='_blank' rel='noreferrer'>
                {this.provider.props.i18n.t('importing.view_transaction_data')}
              </a>
            );
          }

          messageTitle = this.provider.props.i18n.t('importing.transactions_completed');
        }
      }

      // Clone job
      if (clonedRequest) {
        const { id, name } = clonedRequest;

        if (this.provider.areCloneJobsComplete() ){
          this.provider.setGeneralProperty({ hasStaleData : true });
        }

        messageTitle = this.provider.props.i18n.t('bid_request.copy_ready');
        const text = this.provider.props.i18n.t('bid_request.click_here_to_view_name', { name });

        messageDescription = (
          <a href={`/bid_requests/${id}/dashboard`} target='_blank' rel='noreferrer'>{text}</a>
        );
      }

      // Bid Submission Job
      if (bidSubmission) {
        const {
          request,
          round_no: roundNo,
          bid_type: bidType,
          is_answer_only_submission: isAnswerOnlySubmission,
          bid_response: bidResponse
        } = bidSubmission;
        const { id, name } = request;
        const isDraftSubmission = ['Draft', 'draft'].includes(bidType);
        const { t } = this.provider.props.i18n;
        const wasSubmittedByNonVendor = !!bidResponse?.modified_by_id;

        this.provider.setState({
          bidSubmissionChangeCount: this.provider.state.bidSubmissionChangeCount + 1,
          submissionInfo: bidSubmission
        });

        key = BID_SUBMISSION_SUBMITTED_NOTIF_KEY;

        if (wasSubmittedByNonVendor) {
          messageTitle = t('bid_response.bid_submission.submitted');
          messageDescription = t('bid_response.bid_submission.submission_modified_description');
        } else if (isAnswerOnlySubmission) {
          messageTitle = t('bid_response.bid_submission.answers_submitted');
          messageDescription = t('bid_response.bid_submission.line_item_answers_have_been_submitted');
        } else {
          messageTitle = t('bid_response.bid_submission.submitted');

          messageDescription = (
            <a href={`/bid_requests/${id}/bid_submission`} target='_blank' rel='noreferrer'>
              <div style={{ color: '#333333' }}>
                <div><strong>{t('general.request_name')}</strong>: {name}</div>
                {roundNo > 0 && (
                  <div><strong>{t('general.round_no')}</strong>: {roundNo}</div>
                )}
                <div><strong>{t('bid_response.bid_submission.type')}</strong>: {bidType}</div>
              </div>
              <p>
                <em>{t('bid_response.bid_submission.click_here_to_view_submission')}</em>
              </p>
            </a>
          );

          if (isDraftSubmission) {
            messageTitle = t('bid_response.bid_submission.saved_draft');
            messageDescription = t('bid_response.bid_submission.saved_draft_description');
          }
        }

        notification.destroy(BID_SUBMISSION_PROCESSING_NOTIF_KEY);
        notification.destroy(BID_SUBMISSION_SUBMITTED_NOTIF_KEY);

        // If a user is currently on the bid submission page when the job completes, we don't want
        // to spam them with both a modal "success" message and a notification "success" message.
        // Only show them them the notification that the job is complete if they are on a different
        // page.
        if (!hasJobError && isBidSubmissionPage) return;
      }

      // File Download Job
      if (fileDownload) {
        const { url, status } = fileDownload;

        if (status === 'completed') {
          messageTitle = this.provider.props.i18n.t('general.file_download_start_soon');

          messageDescription = (
            <a href={url} download>
              {this.provider.props.i18n.t('general.file_download_click')}
            </a>
          );
          generateDownloadFromUrl(url);
        }
        else {
          messageType = 'error';

          messageTitle = this.provider.props.i18n.t('general.file_download_error');

          messageDescription = (
            <p style={{ color: '#333333' }}>
              <div><strong>{this.provider.props.i18n.t('general.file_download_error_message')}</strong></div>
            </p>
          );
        }
      }
    }

    return {
      messageDuration,
      messageDescription,
      messageTitle,
      messageType,
      key
    };
  }
}

export default PusherService;
